$(document).ready(function() {
    function getPosts(city, ref) {

        var ajaxPosts = {
            "modelName": "AddressGeneral",
            "calledMethod": "getWarehouses",
            "methodProperties": {
                "Language": "ru",
                "CityName": city,
                "CityRef": ref,
            },
            "apiKey": "29a5e79ebad6658c98b224ed5b56476e" // "285112c21c69ec051a569bcbf6390a19" // "1c5ac51da17f796604b0ccd505ce2502" // "876068ed947bb2097781de85e4a4a33c"
        }

        var settings = {
            "async": true,
            "crossDomain": true,
            "url": "https://api.novaposhta.ua/v2.0/json/",
            "method": "POST",
            "headers": {
                "content-type": "application/json",

            },
            "processData": false,
            "data": JSON.stringify(ajaxPosts)
        };
        $.ajax(settings).done(function(response) {
            createSelectPosts(response.data);

        });
        $('.selectpicker').selectpicker('refresh');
    }

    var ajaxCities = {
        "modelName": "Address",
        "calledMethod": "getCities",
        "methodProperties": {
            "": ""
        },
        "apiKey": "29a5e79ebad6658c98b224ed5b56476e" // "285112c21c69ec051a569bcbf6390a19" // "1c5ac51da17f796604b0ccd505ce2502" // "876068ed947bb2097781de85e4a4a33c"
    };
    var settings = {
        "async": true,
        "crossDomain": true,
        "url": "https://api.novaposhta.ua/v2.0/json/",
        "method": "POST",
        "headers": {
            "content-type": "application/json",
        },
        "processData": false,
        "data": JSON.stringify(ajaxCities)
    };

    $.ajax(settings).done(function(response) {
        createSelectCity(response.data);
    });

    function createSelectCity(data) {
        for (var key in data) {
            var tpl = `<option value="` + data[key].Description + `" data-value="` + data[key].Ref + `">` + data[key].Description + `</option>`
            $('.delivery_np #cityDelivery_np').append(tpl);
            // if (data[key].CityID == '4') {
            //     $('.delivery_np #cityDelivery_np').selectpicker('val', data[key].Ref);
            //     getPosts(data[key].Description, data[key].Ref);
            // }
        }

        $('.delivery_np .selectpicker').selectpicker('refresh');
    }

    function createSelectPosts(data) {
        var np_replace = /"Новая Почта"|"Новая Пошта"|"Нова Пошта"|" Новая Пошта"|"Нова Почта"|"Новая почта"|"Новая Почтая"|" Новая Почта"/gm
        for (var key in data) {
            // if (data[key].Number < 1000) {
                var tpl = `<option data-number="` + data[key].Number + `" value="` + data[key].Description.replace('��������� ', '').replace(np_replace, '') + `">` + data[key].Description.replace('��������� ', '').replace(np_replace, '') + `</option>`
                $('.delivery_np #postDelivery_np').append(tpl)
            // }
        }
        $('.delivery_np #postDelivery_np').show();
        $('.delivery_np .selectpicker').selectpicker('refresh');
    }
    var popularCities = ['Київ', 'Одеса', 'Львів', 'Дніпро', 'Харків'];
    var popularCitiesHtml = '<div class="popular-cities">';
    for (var i = 0; i < popularCities.length; i++) {
      popularCitiesHtml += `<a href="#" class="popular-city" data-city="${popularCities[i]}">${popularCities[i]}</a>`;
      if (i < popularCities.length - 1) {
        popularCitiesHtml += '<span class="city-separator">  </span>';
      }
    }
    popularCitiesHtml += '</div>';
    $('#cityDelivery_np').after(popularCitiesHtml);
    $('.delivery_np').on('click', '.popular-city', function(e) {
      e.preventDefault();
      var city = $(this).data('city');
      $('#cityDelivery_np').val(city).trigger('change');
    });


    // var justin_settings = {
    //     "url": `https://${window.location.host}/justin/cities`,
    //     "method": "POST",
    // }
    //
    // $.ajax(justin_settings).done(function(response) {
    //     createJustinSelectCity(response);
    // });
    //
    // function getJustinBranch(uuid) {
    //     var justin_branch = {
    //         "url": `https://${window.location.host}/justin/branches`,
    //         "method": "POST",
    //         "data": {
    //             "uuid": uuid
    //         }
    //     }
    //     $.ajax(justin_branch).done(function(response) {
    //         createJustinSelectBranch(response);
    //     });
    // }

    // function createJustinSelectCity(data) {
    //     //debugger;
    //     data.cities.forEach(function(city) {
    //         let tpl = `<option value="` + city[1] + `" data-value="` + city[0] + `">` + city[1] + `</option>`
    //         $('.delivery_ju #cityDelivery_ju').append(tpl);
    //     });
    //     getJustinBranch(data.cities[0][0]);
    //     $('.delivery_ju .selectpicker').selectpicker('refresh');
    // }

    // function createJustinSelectBranch(data) {
    //     data.branches.forEach(function(item) {
    //         var tpl = `<option value="` + item[0] + `" data-number="` + item[1] + `">` + item[0] + `</option>`
    //         $('.delivery_ju #postDelivery_ju').append(tpl);
    //     });
    //     $('.delivery_ju #postDelivery_ju').show();
    //     $('.delivery_ju .selectpicker').selectpicker('refresh');
    // }



    $('.delivery_np #cityDelivery_np').change(function() {
        $('.delivery_np #postDelivery_np option').remove();
        var text = $("option:selected", this).text();
        var value = $(this).data('value');
        if (text !== "") {
            $('.delivery_np .delivery_np_field').show();
            getPosts(text, value);
        } else {
            $('.delivery_np .delivery_np_field').hide();
        }
    });

    // $('.delivery_ju #cityDelivery_ju').change(function() {
    //     $('.delivery_ju #postDelivery_ju option').remove();
    //     $('.delivery_ju #postDelivery_ju').hide();
    //     var value = $("option:selected", this).data('value');
    //     getJustinBranch(value)
    // });

    $('.delivery_ju').hide();

    $('.modal-body-step-2 form').validationEngine("attach", { scroll: false });
    $('.modal-step-3-form').validationEngine("attach", { scroll: false });
    $('.delivery-radio-cnt input').change(function(event) {

        switch (this.value) {
            // case 'ju':
            //     $('.delivery_ju select').each(function(index) {
            //         $(this).attr('name', 'order[order_deliveries][description][]');
            //     });
            //
            //     $('.delivery_hm input').each(function(index) {
            //         $(this).attr('name', '');
            //     });
            //
            //     $('.delivery_np select').each(function(index) {
            //         $(this).attr('name', '');
            //     });
            //     break;
            case 'np':
                $('.delivery_hm input').each(function(index) {
                    $(this).attr('name', '');
                });

                $('.delivery_np select').each(function(index) {
                    $(this).attr('name', 'order[order_deliveries][description][]');
                });

                $('.delivery_ju select').each(function(index) {
                    $(this).attr('name', '');
                });
                break;
            case 'hm':
                $('.delivery_hm input').each(function(index) {
                    $(this).attr('name', 'order[order_deliveries][description][]');
                });

                $('.delivery_np select').each(function(index) {
                    $(this).attr('name', '');
                });

                $('.delivery_ju select').each(function(index) {
                    $(this).attr('name', '');
                });
                break;
            case 'sm':
                $('.delivery_hm input').each(function(index) {
                    $(this).attr('name', '');
                });

                $('.delivery_np select').each(function(index) {
                    $(this).attr('name', '');
                });

                $('.delivery_ju select').each(function(index) {
                    $(this).attr('name', '');
                });

        }

        var delivery_price = $(this).parents('.delivery-radio-cnt').find('.sum_delivery').text()

        if (delivery_price == '') {
            $('.price_delivery_text').parent().hide()
        } else {
            $('.price_delivery_text').parent().show()
        }
        $('.price_delivery_text').text(delivery_price)

        $('.delivery_np,.delivery_hm,.delivery_ju').hide();
        $('.delivery_' + $('.delivery-radio-cnt input:checked').val()).show();
        if ($('.delivery-radio-cnt input:checked').val() == 'hm') {
            $('.delivery_hm form').validationEngine("attach", { scroll: false });
            $('.modal-body-step-3-form').validationEngine('detach');
        } else if ($('.delivery-radio-cnt input:checked').val() == 'sm') {
            $('.delivery_hm form').validationEngine('detach');
            $('.modal-body-step-3-form').validationEngine('detach');
        } else {
            $('.delivery_hm form').validationEngine('detach');
            $('.modal-body-step-3-form').validationEngine("attach", { scroll: false });
        }
    });
    $('.hide_modal').on('click', function(e) {
        // e.preventDefault();
        $('.modal-body-step-1').show();
        $('.modal-body-step-2').hide();
        $('.modal-body-step-3').hide();
        $('.modal-body-step-4').hide();
        $('.thank_modal').hide();
    });
    $('.modal_bucket').on('hidden.bs.modal', function(e) {
        $('.modal-body-step-1').show();
        $('.modal-body-step-2').hide();
        $('.modal-body-step-3').hide();
        $('.modal-body-step-4').hide();
    });
    $('.get_order').click(function(event) {
        event.preventDefault();
        $('.modal-body-step-1').hide();
        $('.modal-body-step-2').show();
    });
    $('.modal-body-step-2 .btn').click(function(event) {
        // event.preventDefault();
        if ($('.modal-body-step-2 form').validationEngine("validate")) {
            $('.modal-body-step-2').hide();
            $('#cityDelivery_np').val('').trigger('change');
            $('.modal-body-step-3').show();
        }
    });
    $('.back_step-1').click(function(event) {
        $('.modal-body-step-3').hide();
        $('.modal-body-step-2').show();
    });

    $('.modal-step-3-form').on('ajax:success', function(e, data, status, xhr) {
        $('.modal-body-step-4').show();
        $('.modal-body-step-3').hide();
        $(".count-bucket").text("0");
        $(".all_count").text("0");
        $(".products_count").text("0");
        $(".summ_products").text("0");
        $(".modal-body-step-1 > .items-bucket").empty();

    }).on('ajax:error', function(e, xhr, status, error) {
        console.log('failed ' + error);
    });

    // $('.send_all').click(function(event) {
    // 	// event.preventDefault();
    // 	if($('.delivery-radio input:checked').val() == 'hm'){
    // 		if($('.delivery_hm form').validationEngine("validate")){

    // 		}
    // 	}else  if($('.delivery-radio input:checked').val() == 'sm') {
    // 		if ($('.modal-step-3-form').validationEngine("validate")) {
    // 			$('.modal-body-step-4').show();
    // 			$('.modal-body-step-3').hide();
    // 		}
    // 	}else {

    // 		if($('.modal-step-3-form').validationEngine("validate")){
    // 			$('.modal-body-step-4').show();
    // 			$('.modal-body-step-3').hide();
    // 		}
    // 	}

    // });

    $('.delivery_input').change(function(){
        switch ($(this).val()) {
            case 'np':
                $('.sum_delivery_price').text(Math.round($('.summ_products').text()/100*2 + 20));
                break
            case 'ju':
                $('.sum_delivery_price').text(Math.round($('.summ_products').text()/100*2 + 15));
                break
            case 'hm':
            case 'sm':
                $('.sum_delivery_price').text('45');
        };
    });

});
