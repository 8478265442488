(function($){
  $.fn.validationEngineLanguage = function(){
  };
  $.validationEngineLanguage = {
    newLang: function(){
      $.validationEngineLanguage.allRules = {
        "required": { // Add your regex rules here, you can take telephone as an example
          "regex": "none",
          "alertText": "! Необхідно заповнити",
          "alertTextCheckboxMultiple": "! Ви повинні вибрати варіант",
          "alertTextCheckboxe": "! Необхідно відзначити"
        },
        "LuhnCheck": {
          "func": function(field, rules, i, options){
            return LuhnValidate(getCardValue());
          },
          "alertText": "* Ви ввели неправильний номер карти."
        },
        "requiredInFunction": {
          "func": function(field, rules, i, options){
            return (field.val() == "test") ? true : false;
          },
          "alertText": "! Field must equal test"
        },
        "minSize": {
          "regex": "none",
          "alertText": "! Мінімум ",
          "alertText2": " символи(ів)"
        },
        "maxSize": {
          "regex": "none",
          "alertText": "! Максимум ",
          "alertText2": " символи(ів)"
        },
        "groupRequired": {
          "regex": "none",
          "alertText": "! You must fill one of the following fields"
        },
        "min": {
          "regex": "none",
          "alertText": "! Мінімальне значення "
        },
        "max": {
          "regex": "none",
          "alertText": "! Максимальне значення "
        },
        "past": {
          "regex": "none",
          "alertText": "! Дата до "
        },
        "future": {
          "regex": "none",
          "alertText": "! Дата від "
        },
        "maxCheckbox": {
          "regex": "none",
          "alertText": "! Не можна вибрати стільки варіантів"
        },
        "minCheckbox": {
          "regex": "none",
          "alertText": "! Будь ласка, виберіть ",
          "alertText2": " опцію(ії)"
        },
        "equals": {
          "regex": "none",
          "alertText": "! Поля не співпадають"
        },
        "creditCard": {
          "regex": "none",
          "alertText": "! Невірний номер кредитної картки"
        },
        "fullName": {
          "regex": /^[a-zA-Z\u0400-\u04FF][a-zA-Z\u0400-\u04FF\s\'\-]{1,30}$/,
          "alertText": "! Введіть ім'я."
        },
        "numberAkreditative": {
          "regex": /\d{4}\/[5-9][0-9]{3}\/[IMPA|impa|EXPA|expa]{4}$/,
          "alertText": "! Невірний номер акредитива. Введіть правильний номер, наприклад: 2508/6587/IMPA"
        },
        "summAkreditative": {
          "regex": /^[1-9]{1,}([\.]?\d{0,2})$/,
          "alertText": "! Невірна сума акредитива. Введіть правильну суму, наприклад: 25411.00"
        },
        "allFullName": {
          "regex": /^[a-zA-Z\u0400-\u04FF\ \']{3,30}\s[a-zA-Z\u0400-\u04FF\ \']{3,30}\s[a-zA-Z\u0400-\u04FF\ \']{3,30}$/,
          "alertText": "* Введіть повністю свої ім'я, прізвище та по батькові, будь ласка."
        },
        "city": {
          "regex": /^[a-zA-Z\u0400-\u04FF\ \']{3,30}$/,
          "alertText": "* Введіть назву міста, в якому Ви хочете працювати."
        },
        "validInn": {
          "regex": /^\d{10}$/,
          "alertText": "* Введіть ІПН"
        },
        "phone": {
          // credit: jquery.h5validate.js / orefalo
          //"regex": /^([\+][0-9]{1,3}[ \.\-])?([\(]{1}[0-9]{2,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
          "regex": /^[\+3]{2}[0-9]{11}$/,
          "alertText": "! Введіть номер телефону, наприклад: +380671234567"
        },
        "new_phone": {
          // credit: jquery.h5validate.js / orefalo
          //"regex": /^([\+][0-9]{1,3}[ \.\-])?([\(]{1}[0-9]{2,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/,
          "regex": /[\+380]{1}[1-9]{1}[0-9]{8}$/,
          "alertText": "! Введіть номер телефону, наприклад: +380671234567"
        },
        "phone_mask": {
          "regex": /[\+380]{1}[(]([0-9]{3})[)][ ]([0-9]{3})-([0-9]{2})-([0-9]{2})$/,
          "alertText": "* Введіть номер телефону в міжнародному форматі, наприклад: +380671234567"
        },
        "email": {
          // Shamelessly lifted from Scott Gonzalez via the Bassistance Validation plugin http://projects.scottsplayground.com/email_address_validation/
          "regex": /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i,
          "alertText": "! Введіть свій e-mail. Наприклад ivanov@gmail.com"
        },
        "email_no_req": {
          "regex": /(^$|^.*@.*\..*$)/i,
          "alertText": "! Введіть свій e-mail. Наприклад ivanov@gmail.com"
        },
        "region": {
          "regex": /^[a-zA-Z\u0400-\u04FF\ \']{5,30}$/,
          "alertText": "* Введіть повнiстю назву областi."
        },
        "tso_region": {
          "regex": /[A-Z0-9]{4}/,
          "alertText": '* Необхiдно обрати область'
        },
        "sity": {
          "regex": /^[a-zA-Z\u0400-\u04FF\ \'.]{3,30}$/,
          "alertText": "* Введіть повнiстю назву мiста."
        },
        "only_lit": {
          "regex": /^[a-zA-Z\u0400-\u04FF\ \'.]{3,30}$/,
          "alertText": "* Введіть тільки літери. Не менше трьох."
        },
        "street": {
          "regex": /^[0-9a-zA-Z\u0400-\u04FF\ \']{3,30}$/,
          "alertText": "* Введіть повнiстю назву вулицi"
        },
        "buyer": {
          "regex": /^[-_0-9a-z\u0400-\u04FF\s'"`\«\»\.\,\:іЇєґ+]{3,150}$/i,
          "alertText": "! Вкажіть назву покупця, наприклад: ТОВ «Ромашка», ПП Петров"
        },
        "inn": {
          "regex": /^((\d){8}|(\d){10})$/,
          "alertText": "! Вкажіть 8-значний код ЄДРПОУ (для юр. осіб)<br>або 10-значний код ІПН (для фіз. осіб)"
        },
        "address": {
          "regex": /^[0-9a-zA-Z\u0400-\u04FF\ \'\"\«\»\.\,\:]{3,100}$/,
          "alertText": "! Вкажіть повну поштову адресу, наприклад: 49094, м.Дніпро, вул. Набережна Перемоги, 50"
        },
        "fullName2": {
          "regex": /^[a-zA-Z\u0400-\u04FF][a-zA-Z\u0400-\u04FF\s\'\-]{1,50}$/,
          "alertText": "! Введіть прізвище, ім'я та по батькові, наприклад: Іванов Іван Іванович"
        },
        "scope": {
          "regex": /^[a-zA-Z\u0400-\u04FF\ \']{5,30}$/,
          "alertText": "* Введіть повністю сферу діяльності"
        },
        "opt_email_len6": {
          "func": function(field, rules, i, options){
            return OptionalEmailValidate_len6(field.val());
          },
          "alertText": "Невірний формат email"
        },
        "opt_email": {
          "func": function(field, rules, i, options){
            return OptionalEmailValidate(field.val());
          },
          "alertText": "Невірний формат email"
        },
        "integer": {
          "regex": /^[\-\+]?\d+$/,
          "alertText": "! Не ціле число"
        },
        "number": {
          // Number, including positive, negative, and floating decimal. credit: orefalo
          "regex": /^(\d*\.)?\d+$/,
          "alertText": "! Неправильне число з плаваючою точкою"
        },
        "date": {
          "regex": /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/,
          "alertText": "! Неправильна дата (повинна бути в ДД.MM.ГГГГ форматі)"
        },
        "date_new": {
          "regex": /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/,
          "alertText": "* Неправильна дата (повинно бути в ДД.MM.РРРР форматі)"
        },
        "date_mask": {
          "regex": /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/,
          "alertText": "* Введіть коректну дату народження у форматі ДД/ММ/РРРР. Наприклад 21/07/1991"
        },
        "ipv4": {
          "regex": /^((([01]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))[.]){3}(([0-1]?[0-9]{1,2})|(2[0-4][0-9])|(25[0-5]))$/,
          "alertText": "! Неправильна IP-адреса"
        },
        "url": {
          "regex": /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
          "alertText": "! Неправильний URL"
        },
        "onlyNumberSp": {
          "regex": /^[0-9\ ]+$/,
          "alertText": "! Тільки числа"
        },
        "onlyLetterSp": {
          "regex": /^[a-zA-Z\u0400-\u04FF\ \']+$/,
          "alertText": "! Тільки літери"
        },
        "onlyLetterNumber": {
          "regex": /^[0-9a-zA-Z\u0400-\u04FF]+$/,
          "alertText": "! Заборонені спеціальні символи"
        },
        "phoneEmail": {
          "regex": /(^[\+3]{2}[0-9]{11}$)|(^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$)/,
          "alertText": "! Введіть номер телефону в міжнародному форматі (+380671234568), або e-mail"
        },
        "select_OS": {
          "func": function(field, rules, i, options){
            reg = /^[0-9a-zA-Z\s]+$/;
            return reg.test(
                $(field).text()
            );
          },
          "alertText": "! Оберіть ОС"
        },
        // --- CUSTOM RULES -- Those are specific to the demos, they can be removed or changed to your likings
        "ajaxUserCall": {
          "url": "ajaxValidateFieldUser",
          // you may want to pass extra data on the ajax call
          "extraData": "name=eric",
          "alertText": "! Цей користувач вже зайнятий",
          "alertTextLoad": "! Перевірка, почекайте..."
        },
        "ajaxNameCall": {
          // remote json service location
          "url": "ajaxValidateFieldName",
          // error
          "alertText": "! Це ім'я вже зайняте",
          // if you provide an "alertTextOk", it will show as a green prompt when the field validates
          "alertTextOk": "! Це ім'я доступно",
          // speaks by itself
          "alertTextLoad": "! Перевірка, почекайте..."
        },
        "validate2fields": {
          "alertText": "! Будь ласка, введіть HELLO"
        },
        "okpo": {
          "regex": /^[0-9]{8}$/,
          "alertText": "* У ЄДРПОУ допускаються тільки 8 цифр (наприклад: 12345678)"
        },
        "paytophone": {
          "regex": /^[0-9\ ]{4}$/,
          "alertText": "* Допускається введення тільки цифр"
        },
        "garantii": {
          "regex": /^G[0-9]{4}\/[0-9]{4}[\S]*$/,
          "alertText": "! Введіть номер гарантії, наприклад: G1234/9876"
        },
        "time": {
          "regex": /^(([0,1][0-9])|(2[0-3])):[0-5][0-9]$/,
          "alertText": "* Введіть час у форматі гг:хх"
        },
        "skype": {
          "regex": /^[a-zA-Z][a-zA-Z0-9.,_-]{5,31}$/,
          "alertText": "* Введіть валідний логін скайпу"
        },
        "onlyNumLetters": {
          "regex": /^[0-9a-zA-Z\u0400-\u04FF]{0,255}$/,
          "alertText": "* Тільки цифри та букви"
        },
        "organisation_name": {
          "regex": /^[^\*\@]*$/,
          "alertText": "! Заборонені спеціальні символи"
        },
        "login": {
          "regex": /^[^\*\@\№\#]*$/,
          "alertText": "! Заборонені спеціальні символи"
        },
        "organisation_id": {
          "regex": /^[1-9]+[0-9]*$/,
          "alertText": "! Тільки числа"
        },
        "onlyNumber": {
          "regex": /^[\d]+$/,
          "alertText": "! Тільки числа"
        },
      };

    }
  };
  $.validationEngineLanguage.newLang();

  function OptionalEmailValidate_len6(email)
  {
    var regExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
    if (email != '')
    {
      if (email.length < 6)
      {
        return false;
      }
      return regExp.test(email);
    }
    return true;
  }

  function OptionalEmailValidate(email)
  {
    var regExp = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i;
    if (email != '')
    {
      return regExp.test(email);
    }
    return true;
  }
})(jQuery);



